
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  html,
  body,
  #root,
  .app,
  .content {
    height: 100%;
    width: 100%;
    font-family: 'Inter', sans-serif;
  }

  .app{
    display: flex ; 
    position: relative ;
  
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  /* track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-track:hover {
    background: #555;
  }

